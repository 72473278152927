/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { getUserByToken, register } from "../core/_requests";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import { useAuth } from "../core/Auth";
import { AxiosError } from "axios";
import axiosInstance from "../../../utils/axiosInstance";

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  confirm_password: "",
  state: "",
  city: "",
  division: "",
};

const registrationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("First name is required"),
  last_name: Yup.string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("Last name is required"),
  email: Yup.string().email("Wrong email format").required("Email is required"),
  password: Yup.string()
    .min(8, "Minimum 8 characters")
    .max(50, "Maximum 50 characters")
    .required("Password is required"),
  confirm_password: Yup.string()
    .min(8, "Minimum 8 characters")
    .max(50, "Maximum 50 characters")
    .required("Password confirmation is required")
    .oneOf([Yup.ref("password")], "Password and Confirm Password didn't match"),
  state: Yup.string().required("You must select a state"),
  city: Yup.string().required("You must select a city"),
  division: Yup.string().required("You must select a division"),
});

export function Registration() {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { confirm_password, ...data } = values;

        console.log(data);

        await axiosInstance.post("/users", data);

        setStatus(
          "Successfully registered as GUEST. Provide your email to an admin to get role and login privileges."
        );
        setLoading(false);
      } catch (error) {
        const axiosError = error as AxiosError;
        console.error(error);
        saveAuth(undefined);
        setStatus(axiosError.response?.data.message);
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className="mb-11 text-center">
        {/* begin::Title */}
        <h1 className="mb-3 text-dark fw-bolder">Sign Up</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className="mb-8 fv-row">
        <label className="form-label fw-bolder text-dark fs-6">
          First name
        </label>
        <input
          placeholder="First name"
          type="text"
          autoComplete="off"
          {...formik.getFieldProps("first_name")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid":
                formik.touched.first_name && formik.errors.first_name,
            },
            {
              "is-valid":
                formik.touched.first_name && !formik.errors.first_name,
            }
          )}
        />
        {formik.touched.first_name && formik.errors.first_name && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.first_name}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      <div className="mb-8 fv-row">
        {/* begin::Form group Lastname */}
        <label className="form-label fw-bolder text-dark fs-6">Last name</label>
        <input
          placeholder="Last name"
          type="text"
          autoComplete="off"
          {...formik.getFieldProps("last_name")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid": formik.touched.last_name && formik.errors.last_name,
            },
            {
              "is-valid": formik.touched.last_name && !formik.errors.last_name,
            }
          )}
        />
        {formik.touched.last_name && formik.errors.last_name && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.last_name}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>

      {/* begin::Form group Email */}
      <div className="mb-8 fv-row">
        <label className="form-label fw-bolder text-dark fs-6">Email</label>
        <input
          placeholder="Email"
          type="email"
          autoComplete="off"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control bg-transparent",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className="mb-8 fv-row" data-kt-password-meter="true">
        <div className="mb-1">
          <label className="form-label fw-bolder text-dark fs-6">
            Password
          </label>
          <div className="mb-3 position-relative">
            <input
              type="password"
              placeholder="Password"
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className="mb-5 fv-row">
        <label className="form-label fw-bolder text-dark fs-6">
          Confirm Password
        </label>
        <input
          type="password"
          placeholder="Password confirmation"
          autoComplete="off"
          {...formik.getFieldProps("confirm_password")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid":
                formik.touched.confirm_password &&
                formik.errors.confirm_password,
            },
            {
              "is-valid":
                formik.touched.confirm_password &&
                !formik.errors.confirm_password,
            }
          )}
        />
        {formik.touched.confirm_password && formik.errors.confirm_password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.confirm_password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group state*/}
      <div className="mb-8 fv-row">
        <label className="form-label fw-bolder text-dark fs-6">State</label>
        <input
          placeholder="State"
          type="text"
          autoComplete="off"
          {...formik.getFieldProps("state")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid": formik.touched.state && formik.errors.state,
            },
            {
              "is-valid": formik.touched.state && !formik.errors.state,
            }
          )}
        />
        {formik.touched.state && formik.errors.state && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.state}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group city*/}
      <div className="mb-8 fv-row">
        <label className="form-label fw-bolder text-dark fs-6">City</label>
        <input
          placeholder="City"
          type="text"
          autoComplete="off"
          {...formik.getFieldProps("city")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid": formik.touched.city && formik.errors.city,
            },
            {
              "is-valid": formik.touched.city && !formik.errors.city,
            }
          )}
        />
        {formik.touched.city && formik.errors.city && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.city}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group division*/}
      <div className="mb-8 fv-row">
        <label className="form-label fw-bolder text-dark fs-6">HQ</label>
        <input
          placeholder="Division"
          type="text"
          autoComplete="off"
          {...formik.getFieldProps("division")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid": formik.touched.division && formik.errors.division,
            },
            {
              "is-valid": formik.touched.division && !formik.errors.division,
            }
          )}
        />
        {formik.touched.division && formik.errors.division && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.division}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_up_submit"
          className="mb-5 btn btn-lg btn-primary w-100"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Submit</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...{" "}
              <span className="align-middle spinner-border spinner-border-sm ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/auth/login">
          <button
            type="button"
            id="kt_login_signup_form_cancel_button"
            className="mb-5 btn btn-lg btn-light-primary w-100"
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  );
}
