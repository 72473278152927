
export default function CopyButtonWrapper({ children }: { children: string }) {
  return (
    <span className="tw-whitespace-nowrap">
      {children}
      &nbsp; &nbsp;
      <i
        className="fa-regular fa-copy"
        onClick={() => navigator.clipboard.writeText(children)}
      ></i>
    </span>
  );
}
