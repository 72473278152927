/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../app/modules/auth";
import { Languages } from "./Languages";
import { toAbsoluteUrl } from "../../../helpers";

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth();
  return (
    <div
      className="py-4 menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="px-3 menu-item">
        <div className="px-3 menu-content d-flex align-items-center">
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src={toAbsoluteUrl("/media/avatars/blank.png")} />
          </div>
          <div className="d-flex flex-column">
            <div className="fw-bolder tw-flex tw-flex-wrap tw-flex-col fs-5">
              {currentUser?.first_name} {currentUser?.last_name}
              <span className="py-1 px-2 badge badge-light-success fw-bolder fs-8 tw-max-w-min">
                {currentUser?.role}
              </span>
            </div>
          </div>
        </div>
        <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
          {currentUser?.email}
        </a>
      </div>

      <div className="my-2 separator"></div>

      <div className="px-5 menu-item">
        <a onClick={logout} className="px-5 menu-link">
          Sign Out
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
