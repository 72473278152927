import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { read, utils } from "xlsx";
import CopyButtonWrapper from "../../components/shared/CopyButtonWrapper";
import { Chemist } from "../../entityTypes";
import axiosInstance from "../../utils/axiosInstance";
import catchAsync from "../../utils/catchAsync";

const BulkUploadChemists = () => {
  const navigate = useNavigate();
  const [chemists, setChemists] = useState<Chemist[]>([]);
  const bulkUploadRef = useRef<HTMLInputElement>(null);
  const handleSelectFile = () => {
    if (bulkUploadRef.current?.files?.length) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(bulkUploadRef.current?.files[0]);
      reader.onload = async (e) => {
        const bufferArray = e.target?.result;
        const wb = read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data: Array<any> = utils.sheet_to_json(ws);
        console.log(data);
        setChemists(data);
      };
    }
  };
  const handleBulkUpload = async () => {
    if (
      !chemists.every((chem) => {
        let valid =
          true &&
          chem.chemist_name &&
          chem.city &&
          chem.division &&
          chem.state &&
          chem.hq_id &&
          chem.address;
        return valid;
      })
    ) {
      toast.error("1 or more required fields are missing..");
      return;
    }

    await Promise.all(
      chemists.map((doc) => {
        if (doc.contact_number) {
          doc.contact_number = doc.contact_number.toString();
        }
        return catchAsync(async () => {
          const res = await axiosInstance.post("/chemists", doc);
          toast.success(res.data.message);
          navigate("/chemists");
        })();
      })
    );
  };

  return (
    <section>
      <header className="tw-mb-12 tw-flex tw-flex-wrap tw-items-center tw-justify-between">
        <h1 className="fs-2 fw-bold text-capitalize">chemists list</h1>
        <div className="tw-flex tw-gap-3">
          <input
            type="file"
            ref={bulkUploadRef}
            accept=".xlsx"
            className="form-control"
          />
          <button className="btn btn-primary" onClick={handleSelectFile}>
            Select File
          </button>
        </div>
      </header>
      <div className="py-3 px-5 bg-white rounded table-responsive">
        <table className="table table-row-dashed">
          <thead>
            <tr className="align-middle text-uppercase fs-5 fw-bold">
              {[
                "name",
                "city",
                "division",
                "state/zone",
                "hq",
                "email",
                "contact person",
              ].map((header) => (
                <th
                  key={header}
                  className={
                    "" +
                    // centered columns
                    (["action"].includes(header) ? "text-center" : "")
                  }
                >
                  <span className="tw-whitespace-nowrap">
                    {header}
                    &nbsp;
                    {/* sorted columns */}
                    {["city", "division", "state/zone"].includes(header) && (
                      <i className="fa-solid fa-sort"></i>
                    )}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="fw-semibold">
            {chemists.map((chemist) => (
              <tr key={chemist.id} className="align-middle">
                <td className="text-primary text-capitalize">
                  {chemist.chemist_name}
                </td>
                <td className="text-capitalize">{chemist.city}</td>
                <td className="text-capitalize">{chemist.division}</td>
                <td className="text-capitalize">{chemist.state}</td>
                <td className="text-capitalize">{chemist.hq_id}</td>
                <td>
                  <CopyButtonWrapper>{chemist.email}</CopyButtonWrapper>
                </td>
                <td className="text-capitalize">
                  {chemist.contact_person_name}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button className="btn btn-primary" onClick={handleBulkUpload}>
        Bulk Upload
      </button>
    </section>
  );
};

export default BulkUploadChemists;
