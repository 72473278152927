import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { removeAuth } from "../modules/auth";

const catchAsync = (fn: () => unknown): (() => Promise<void>) => {
  return async (): Promise<void> => {
    try {
      const res = await fn();

      console.log(res);
      //@ts-ignore
      if (res.data.message) {
        //@ts-ignore
        toast.success(res.data.message);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      const errorMessage = axiosError.response?.data.message;
      toast.error(errorMessage);

      if (errorMessage === "invalid token" || errorMessage === "jwt expired") {
        localStorage.removeItem("token");
        removeAuth();
        window.location.href = "/auth";
      }
      if (errorMessage === "jwt malformed") {
        window.location.reload();
      }
    }
  };
};

export default catchAsync;
