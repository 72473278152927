type Props = {
  className: string
  description: string
  color: string
  img: string
}

const CardsWidget20 = ({className, description, color, img}: Props) => (
  <div
    className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
    style={{
      backgroundColor: color,
      backgroundImage: `url('${img}')`,
    }}
  >
    <div className='pt-5 card-header'>
      <div className='card-title d-flex flex-column'>
        <span className='text-white fs-2hx fw-bold me-2 lh-1 ls-n2'>69</span>

        <span className='pt-1 text-white opacity-75 fw-semibold fs-6'>{description}</span>
      </div>
    </div>
    <div className='pt-0 card-body d-flex align-items-end'>
      <div className='mt-3 d-flex align-items-center flex-column w-100'>
        <div className='mt-auto mb-2 text-white opacity-75 d-flex justify-content-between fw-bold fs-6 w-100'>
          <span>43 Pending</span>
          <span>72%</span>
        </div>

        <div className='mx-3 bg-white bg-opacity-50 rounded h-8px w-100'>
          <div
            className='bg-white rounded h-8px'
            role='progressbar'
            style={{width: '72%'}}
            aria-valuenow={50}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div>
      </div>
    </div>
  </div>
)
export {CardsWidget20}
