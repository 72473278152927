import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { MenuTestPage } from "../pages/MenuTestPage";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper";
import { useAuth } from "../modules/auth";
import BulkUploadChemists from "../pages/custom/BulkUploadChemists";

const PrivateRoutes = () => {
  const { currentUser } = useAuth();
  // const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  // const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  // const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  // const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  // const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
  // const UsersPage = lazy(
  //   () => import("../modules/apps/user-management/UsersPage")
  // );

  // custom pages
  const DoctorsListPage = lazy(() => import("../pages/custom/DoctorsList"));
  const BulkUploadDoctorsPage = lazy(
    () => import("../pages/custom/BulkUploadDoctors")
  );
  const DoctorsCRMPage = lazy(() => import("../pages/custom/DoctorCRM"));
  const AddMonthlyEntryForCrmPage = lazy(
    () => import("../pages/custom/MonthlyEntryForCrm")
  );
  const DoctorInfoPage = lazy(() => import("../pages/custom/DoctorInfo"));
  const ChemistListPage = lazy(() => import("../pages/custom/ChemistList"));
  const BulkUploadChemistsPage = lazy(
    () => import("../pages/custom/BulkUploadChemists")
  );
  const ChemistInfoPage = lazy(() => import("../pages/custom/ChemistInfoPage"));
  const ProductListPage = lazy(() => import("../pages/custom/ProductList"));
  const StocklistListPage = lazy(() => import("../pages/custom/StockListList"));
  const UserListPage = lazy(() => import("../pages/custom/UserList"));
  const OrderListPage = lazy(() => import("../pages/custom/OrderList"));
  const CreateOrderPage = lazy(() => import("../pages/custom/CreateOrder"));
  const OrderDetailsPage = lazy(() => import("../pages/custom/OrderDetails"));
  const SalesPage = lazy(() => import("../pages/custom/Sales"));
  const CreateSalePage = lazy(() => import("../pages/custom/CreateSale"));
  const PaymentListPage = lazy(() => import("../pages/custom/PaymentList"));
  const ApprovePaymentPage = lazy(
    () => import("../pages/custom/ApprovePayment")
  );
  const InvoicesPage = lazy(() => import("../pages/custom/Invoices"));
  const ChemistPaymntsPage = lazy(
    () => import("../pages/custom/ChemistPayment")
  );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route
          path="auth/*"
          element={
            <Navigate
              to={currentUser?.role === "MR" ? "/payments" : "/doctors/master"}
            />
          }
        />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route path="builder" element={<BuilderPageWrapper />} />
        <Route path="menu-test" element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path="doctors/master"
          element={
            <SuspensedView>
              <DoctorsListPage />
            </SuspensedView>
          }
        />
        <Route
          path="doctors/crm"
          element={
            <SuspensedView>
              <DoctorsCRMPage />
            </SuspensedView>
          }
        />
        <Route
          path="doctors/crm/:id"
          element={
            <SuspensedView>
              <AddMonthlyEntryForCrmPage />
            </SuspensedView>
          }
        />
        <Route
          path="doctors/:id"
          element={
            <SuspensedView>
              <DoctorInfoPage />
            </SuspensedView>
          }
        />
        <Route
          path="chemists"
          element={
            <SuspensedView>
              <ChemistListPage />
            </SuspensedView>
          }
        />
        <Route
          path="bulk-upload/chemists"
          element={
            <SuspensedView>
              <BulkUploadChemists />
            </SuspensedView>
          }
        />
        <Route
          path="bulk-upload/doctors"
          element={
            <SuspensedView>
              <BulkUploadDoctorsPage />
            </SuspensedView>
          }
        />
        <Route
          path="sales"
          element={
            <SuspensedView>
              <SalesPage />
            </SuspensedView>
          }
        />
        <Route
          path="sales/new"
          element={
            <SuspensedView>
              <CreateSalePage />
            </SuspensedView>
          }
        />
        <Route
          path="chemists/:id"
          element={
            <SuspensedView>
              <ChemistInfoPage />
            </SuspensedView>
          }
        />
        <Route
          path="products"
          element={
            <SuspensedView>
              <ProductListPage />
            </SuspensedView>
          }
        />
        <Route
          path="stocklists"
          element={
            <SuspensedView>
              <StocklistListPage />
            </SuspensedView>
          }
        />
        <Route
          path="users"
          element={
            <SuspensedView>
              <UserListPage />
            </SuspensedView>
          }
        />
        <Route
          path="orders"
          element={
            <SuspensedView>
              <OrderListPage />
            </SuspensedView>
          }
        />
        <Route
          path="orders/create-order"
          element={
            <SuspensedView>
              <CreateOrderPage />
            </SuspensedView>
          }
        />
        <Route
          path="orders/:id"
          element={
            <SuspensedView>
              <OrderDetailsPage />
            </SuspensedView>
          }
        />
        <Route
          path="sales"
          element={
            <SuspensedView>
              <SalesPage />
            </SuspensedView>
          }
        />
        <Route
          path="payments"
          element={
            <SuspensedView>
              <PaymentListPage />
            </SuspensedView>
          }
        />
        <Route
          path="chemist-payments/:id"
          element={
            <SuspensedView>
              <ChemistPaymntsPage />
            </SuspensedView>
          }
        />
        <Route
          path="invoices"
          element={
            <SuspensedView>
              <InvoicesPage />
            </SuspensedView>
          }
        />
        <Route
          path="approve-payment"
          element={
            <SuspensedView>
              <ApprovePaymentPage />
            </SuspensedView>
          }
        />
        {/* <Route */}
        {/*   path="crafted/pages/profile/*" */}
        {/*   element={ */}
        {/*     <SuspensedView> */}
        {/*       <ProfilePage /> */}
        {/*     </SuspensedView> */}
        {/*   } */}
        {/* /> */}
        {/* <Route */}
        {/*   path="crafted/pages/wizards/*" */}
        {/*   element={ */}
        {/*     <SuspensedView> */}
        {/*       <WizardsPage /> */}
        {/*     </SuspensedView> */}
        {/*   } */}
        {/* /> */}
        {/* <Route */}
        {/*   path="crafted/widgets/*" */}
        {/*   element={ */}
        {/*     <SuspensedView> */}
        {/*       <WidgetsPage /> */}
        {/*     </SuspensedView> */}
        {/*   } */}
        {/* /> */}
        {/* <Route */}
        {/*   path="crafted/account/*" */}
        {/*   element={ */}
        {/*     <SuspensedView> */}
        {/*       <AccountPage /> */}
        {/*     </SuspensedView> */}
        {/*   } */}
        {/* /> */}
        {/* <Route */}
        {/*   path="apps/chat/*" */}
        {/*   element={ */}
        {/*     <SuspensedView> */}
        {/*       <ChatPage /> */}
        {/*     </SuspensedView> */}
        {/*   } */}
        {/* /> */}
        {/* <Route */}
        {/*   path="apps/user-management/*" */}
        {/*   element={ */}
        {/*     <SuspensedView> */}
        {/*       <UsersPage /> */}
        {/*     </SuspensedView> */}
        {/*   } */}
        {/* /> */}
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
