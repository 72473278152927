/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { useAuth } from "../../../../../app/modules/auth";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";

const SidebarMenuMain = () => {
  const intl = useIntl();
  const { currentUser } = useAuth();

  return (
    <>
      {["SuperAdmin", "HO", "RSM", "ASM", "MR"].includes(
        currentUser?.role as string
      ) && (
          <SidebarMenuItemWithSub
            to="/doctors"
            title="Doctors"
            fontIcon="bi-hospital"
          >
            <SidebarMenuItem
              to="/doctors/master"
              title={"Master Doctors"}
              hasBullet
            />
            <SidebarMenuItem to="/doctors/crm" title={"CRM"} hasBullet />
          </SidebarMenuItemWithSub>
        )}

      {["SuperAdmin", "HO", "RSM", "ASM", "MR"].includes(
        currentUser?.role as string
      ) && (
          <SidebarMenuItem
            to="/chemists"
            title={"Master Chemist"}
            fontIcon="bi-capsule"
          />
        )}
      {["SuperAdmin", "HO", "RSM", "ASM", "MR"].includes(
        currentUser?.role as string
      ) && (
          <SidebarMenuItem
            to="/sales"
            title={"Sales"}
            fontIcon="bi-app-indicator"
          />
        )}
      {["SuperAdmin", "HO"].includes(currentUser?.role as string) && (
        <SidebarMenuItem
          to="/products"
          title={"Product"}
          fontIcon="bi-prescription2"
        />
      )}
      {["SuperAdmin", "HO", "RSM", "ASM", "MR"].includes(
        currentUser?.role as string
      ) && (
          <SidebarMenuItem
            to="/stocklists"
            title={"Stockists"}
            fontIcon="bi-shop-window"
          />
        )}
      {["SuperAdmin"].includes(currentUser?.role as string) && (
        <SidebarMenuItem to="/users" title={"Users"} fontIcon="bi-people" />
      )}
      {["SuperAdmin", "HO", "RSM", "ASM", "MR"].includes(
        currentUser?.role as string
      ) && (
          <SidebarMenuItem to="/orders" title={"Orders"} fontIcon="bi-list-ol" />
        )}
      {["SuperAdmin", "HO", "RSM", "ASM", "MR"].includes(
        currentUser?.role as string
      ) && (
          <SidebarMenuItem
            to="/payments"
            icon="element-11"
            title={"Payments"}
            fontIcon="bi-app-indicator"
          />
        )}
      {["SuperAdmin", "HO"].includes(currentUser?.role as string) && (
        <SidebarMenuItem
          to="/approve-payment"
          icon="element-11"
          title={"Approve Payments"}
          fontIcon="bi-app-indicator"
        />
      )}
      {["SuperAdmin", "HO"].includes(currentUser?.role as string) && (
        <SidebarMenuItemWithSub
          to="/bulk-upload"
          title="Bulk Upload"
          fontIcon="bi-hospital"
        >
          <SidebarMenuItem
            to="/bulk-upload/doctors"
            title={"Bulk Upload Doctors"}
            hasBullet
          />
          <SidebarMenuItem
            to="/bulk-upload/chemists"
            title={"Bulk Upload Chemists"}
            hasBullet
          />
        </SidebarMenuItemWithSub>
      )}
      {/* <SidebarMenuItem
        to="/builder"
        icon="switch"
        title="Layout Builder"
        fontIcon="bi-layers"
      />
      <div className="menu-item">
        <div className="pt-8 pb-2 menu-content">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Crafted
          </span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to="/crafted/pages"
        title="Pages"
        fontIcon="bi-archive"
        icon="element-plus"
      >
        <SidebarMenuItemWithSub
          to="/crafted/pages/profile"
          title="Profile"
          hasBullet={true}
        >
          <SidebarMenuItem
            to="/crafted/pages/profile/overview"
            title="Overview"
            hasBullet={true}
          />
          <SidebarMenuItem
            to="/crafted/pages/profile/projects"
            title="Projects"
            hasBullet={true}
          />
          <SidebarMenuItem
            to="/crafted/pages/profile/campaigns"
            title="Campaigns"
            hasBullet={true}
          />
          <SidebarMenuItem
            to="/crafted/pages/profile/documents"
            title="Documents"
            hasBullet={true}
          />
          <SidebarMenuItem
            to="/crafted/pages/profile/connections"
            title="Connections"
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub
          to="/crafted/pages/wizards"
          title="Wizards"
          hasBullet={true}
        >
          <SidebarMenuItem
            to="/crafted/pages/wizards/horizontal"
            title="Horizontal"
            hasBullet={true}
          />
          <SidebarMenuItem
            to="/crafted/pages/wizards/vertical"
            title="Vertical"
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to="/crafted/accounts"
        title="Accounts"
        icon="profile-circle"
        fontIcon="bi-person"
      >
        <SidebarMenuItem
          to="/crafted/account/overview"
          title="Overview"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/crafted/account/settings"
          title="Settings"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to="/error"
        title="Errors"
        fontIcon="bi-sticky"
        icon="cross-circle"
      >
        <SidebarMenuItem to="/error/404" title="Error 404" hasBullet={true} />
        <SidebarMenuItem to="/error/500" title="Error 500" hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to="/crafted/widgets"
        title="Widgets"
        icon="element-7"
        fontIcon="bi-layers"
      >
        <SidebarMenuItem
          to="/crafted/widgets/lists"
          title="Lists"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/crafted/widgets/statistics"
          title="Statistics"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/crafted/widgets/charts"
          title="Charts"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/crafted/widgets/mixed"
          title="Mixed"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/crafted/widgets/tables"
          title="Tables"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/crafted/widgets/feeds"
          title="Feeds"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <div className="menu-item">
        <div className="pt-8 pb-2 menu-content">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Apps
          </span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to="/apps/chat"
        title="Chat"
        fontIcon="bi-chat-left"
        icon="message-text-2"
      >
        <SidebarMenuItem
          to="/apps/chat/private-chat"
          title="Private Chat"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/apps/chat/group-chat"
          title="Group Chart"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/apps/chat/drawer-chat"
          title="Drawer Chart"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to="/apps/user-management/users"
        icon="abstract-28"
        title="User management"
        fontIcon="bi-layers"
      />
      <div className="menu-item">
        <a
          target="_blank"
          className="menu-link"
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + "/docs/changelog"}
        >
          <span className="menu-icon">
            <KTIcon iconName="code" className="fs-2" />
          </span>
          <span className="menu-title">
            Changelog {process.env.REACT_APP_VERSION}
          </span>
        </a>
      </div> */}
    </>
  );
};

export { SidebarMenuMain };
